import axios from 'axios';
import { EmailRequest } from '../types/Transaction';
import {UserToken, UserSession, HttpRequestHandler} from '@rmstransactions/components';
const host = process.env.REACT_APP_API_HOST;
HttpRequestHandler.setCommonApiKeyHeader()

let emailRequest = {
  url: '',
  config: {},
  clear: function () {
    this.url = '';
    this.config = {};
  },
};
export const sendEmail = async (request: EmailRequest): Promise<any> => {
  emailRequest.clear();
  if (UserSession.isLoginUser()) {
    emailRequest.url = `${host}/auth/send-email-receipt`;
    emailRequest.config = {
      withCredentials: true,
      headers: { 'x-token': UserToken.getValue() },
    };
  } else {
    if (UserToken.getValue()) {
      emailRequest.url = `${host}/anon/send-email-receipt`;
      emailRequest.config = {
        withCredentials: true,
        headers: { 'x-token': UserToken.getValue() },
      };
    } else {
      emailRequest.url = `${host}/support/send-email-receipt`;
      emailRequest.config = {
        withCredentials: false,
      };
    }
  }
  return await axios.post(emailRequest.url, request, emailRequest.config);
};
