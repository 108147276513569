import React from 'react';
import { Spacer, helpers } from '@rmstransactions/components';
import getFormattedPrice from 'helpers/getFormattedPrice';
import { SCHEME_FULL } from '../../../../constants/constants';
import { computeSafetyCheckFlag } from '../../../../calculator/ComputeSafetyCheckFlag';
import * as Styled from './styled';

const CanIChangeTheTerms = (props: any) => {
  const {
    validateData,
    reviewDetailsInput,
    registrationScheme,
    usageType,
    selectedTerm,
    isVehicleLightWeight,
  } = props;

  const isUsageTypePrivate = usageType?.code === 'PRIV';
  const shouldShowContentCtpForThreeMonth =
    isVehicleLightWeight && isUsageTypePrivate;

  const validRenewalTerms = validateData?.validRenewalTerms;
  const showCanIChangeTheTerms =
    reviewDetailsInput?.eligibility?.registrationEligibilityInfo
      ?.validRenewalTerms?.length > 1 &&
    reviewDetailsInput?.eligibility?.registrationEligibilityInfo?.ctpPolicy
      ?.length >= 1;

  let validRenewalTermsInput = Object.assign([], validRenewalTerms);
  validRenewalTermsInput?.sort((a: any, b: any) => {
    if (a.term < b.term) {
      return -1;
    }
    if (a.term > b.term) {
      return 1;
    }
    return 0;
  });

  const priceForRelatedTerm = (term: any) =>
    validateData?.registrationPriceDetails.reduce(
      (filteredPrice: any, price: any) => {
        if (price.term === term) {
          let priceForTerm = (
            <>
              {getFormattedPrice(
                parseFloat(price?.transactionPrice?.grossAmount)
              )}
            </>
          );
          filteredPrice.push(priceForTerm);
        }
        return filteredPrice;
      },
      []
    );

  const contentForOtherTerms = validRenewalTermsInput?.reduce(
    (filteredContent: any, validTerm: any) => {
      if (validTerm.term !== selectedTerm) {
        const term = helpers.ctpMonths(validTerm.term);
        const saftyIsMissing =
          computeSafetyCheckFlag(
            validTerm.safetyInspectionFlag,
            validTerm.hvInspectionFlag
          ) === 'I';
        let termContent = (
          <>
            <Spacer mt={24}>
              <Styled.RenewalTermDetails>
                {term}-month renewal cost will be{' '}
                {priceForRelatedTerm(validTerm.term)}
              </Styled.RenewalTermDetails>
              <p>
                If you want to renew for {term} months you will need a {term}
                -month CTP.
                {shouldShowContentCtpForThreeMonth &&
                  term === 3 &&
                  ` Not all insurance companies offer 3-month CTP insurance for
                private usage.`}
                {saftyIsMissing && ` You will also need a new safety check.`}
                <br />
                Price includes any fees and rebates.
              </p>
            </Spacer>
          </>
        );
        filteredContent.push(termContent);
      }
      return filteredContent;
    },
    []
  );

  //Conditional
  const renewalTermInMonths = helpers.ctpMonths(selectedTerm);
  const otherRegistrationTermMonths = renewalTermInMonths === 12 ? 3 : 12;
  const informationObject = {
    severity: 'INFORMATION',
    message:
      'You can visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a> or call <a href="tel:137788">13 77 88</a> if you want to change the term of a conditional registration to ' +
      otherRegistrationTermMonths +
      ' months if required.',
    messageTitle:
      'Contact Service NSW to change the term of a conditional registration',
  };

  return (
    <>
      {registrationScheme === 'Conditional' && (
        <>
          <Styled.RenewalTermDetails>
            Contact Service NSW to change the term of a conditional registration
          </Styled.RenewalTermDetails>
          <p>
            You can visit a{' '}
            <a
              href='https://www.service.nsw.gov.au/service-centre'
              target='_blank'
              rel='noopener noreferrer'
            >
              service centre
            </a>{' '}
            or call <a href='tel:137788'>13 77 88</a> if you want to change the
            term of a conditional registration to {otherRegistrationTermMonths}{' '}
            months if required.
          </p>
        </>
      )}

      {registrationScheme === SCHEME_FULL && showCanIChangeTheTerms && (
        <>
          <p>
            To change the renewal term, your CTP insurance must match your
            preferred term
          </p>
          <p>
            If you've already purchased a CTP insurance but want to renew for a
            different term, you'll need to cancel your policy and purchase a new
            one with the preferred term.
          </p>
          <Spacer mt={34} />
          {contentForOtherTerms}
        </>
      )}
    </>
  );
};

export default CanIChangeTheTerms;
