import React, { useState } from 'react';
import { Button, tokens, IconEmail, FormGroup, Input } from '@snsw-gel/react';
import { Notification } from '@snsw/react-component-library';
import {
  Svg,
  Spacer,
  validation,
  formMessages,
  DisableTelOnTabletDesktop,
  AnalyticsService,
  EmailConfirmationModal,
} from '@rmstransactions/components';
import { sendEmail } from 'services/emailService';
import { debugLogger } from '../../services/LoggerService';
import { FormEmailWrapper, IconLabelWrapper } from './styled';


const FormEmail = (reference: any) => {
  const [emailValue, setEmailValue] = useState('');
  const [submittedEmail, setSubmittedEmail] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [hasEmailValidationError, setHasEmailValidationError] = useState(false);
  const [emailSubmitStatus, setEmailSubmitStatus] = useState('');
  const [confirmEmailModalOpen, setConfirmEmailModalOpen] =
    useState<boolean>(false);

  const changeEmailAddress = (e: any) => {
    const { value } = e.target;

    if (value === '') {
      setEmailErrorMessage(formMessages.email.isValid);
    } else {
      setEmailErrorMessage('');
    }

    setEmailValue(value);

    // reset email status
    setEmailSubmitStatus('');
    setHasEmailValidationError(false);
  };

  const handleSubmitButton = () => {
    AnalyticsService.ButtonClick('Button Click', 'Send Email');
    if (!validation.email(emailValue)) {
      setEmailErrorMessage(formMessages.email.isValid);
      setHasEmailValidationError(true);
      AnalyticsService.ErrorReport(
        'Inline_validation_error',
        'Send Email',
        'Invalid email address. Please use the format example@email.com'
      );
      return;
    }

    setConfirmEmailModalOpen(true);
  };

  const submitEmail = async () => {
    setEmailSubmitStatus('loading');
    const emailRequest = {
      emailAddress: emailValue,
      uuid: reference.uuid,
    };
    debugLogger('emailRequest', emailRequest);
    sendEmail(emailRequest)
      .then((res) => {
        if (res.status === 200) {
          setEmailSubmitStatus('success');
          setSubmittedEmail(emailValue);
        } else {
          setEmailSubmitStatus('error');
        }
      })
      .catch((err) => {
        setEmailSubmitStatus('error');
        AnalyticsService.ErrorReport(
          'System_error',
          'Send Email',
          'Sorry, we were unable to email your receipt.'
        );
      });
  };
  return (
    <>
      <Spacer mb={3}>
        {emailSubmitStatus === 'error' && (
          <Spacer mb={1} data-testid='notification'>
            <Notification
              variant='error'
              role='alert'
              title={
                <span style={{ fontWeight: 400 }}>
                  <span style={{ marginBottom: '0.25rem', display: 'block' }}>
                    <strong>Sorry, we were unable to email your receipt</strong>
                  </span>
                  <span>
                    Please try again, call us on{' '}
                    <DisableTelOnTabletDesktop phoneNumber='13 77 88' /> or
                    visit a{' '}
                    <a
                      href='https://www.service.nsw.gov.au/service-centre'
                      style={{ color: `${tokens.colors.brand.snswSecondaryBlue}` }}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      service centre
                    </a>
                    .
                  </span>
                </span>
              }
            />
          </Spacer>
        )}

        {emailSubmitStatus === 'success' && (
          <Spacer mb={1} data-testid='notification'>
            <Notification
              variant='success'
              role='status'
              title={
                <span style={{ fontWeight: 400 }}>
                  Your email has been sent to{' '}
                  <strong className='h-word-break'>{submittedEmail}</strong>
                </span>
              }
            />
          </Spacer>
        )}

        <IconLabelWrapper>
          <div className='iconWrapper'>
            <IconEmail />
          </div>
          <p>Send a copy of this receipt to this email address (optional)</p>
        </IconLabelWrapper>
        <FormEmailWrapper>
          <FormGroup
            id='email-form'
            label='Send a copy of this receipt to this email address (optional)'
            hasError={hasEmailValidationError}
            errorMessage={emailErrorMessage}
          >
            <Input
              name='email'
              value={emailValue}
              onChange={(e: any) => changeEmailAddress(e)}
            />

          </FormGroup>
          <Button
            variant='secondary'
            onClick={handleSubmitButton}
            disabled={emailSubmitStatus === 'loading' && true}
            data-testid='submitButton'
          >
            {emailSubmitStatus === 'loading' ? (
              <Svg.Spinner color='#646974' />
            ) : (
              'Send'
            )}
          </Button>
        </FormEmailWrapper>
      </Spacer>
      <EmailConfirmationModal
        emailAddress={emailValue}
        contentName='Registration Renewal receipt'
        onCancel={() => setConfirmEmailModalOpen(false)}
        onConfirm={() => {
          setConfirmEmailModalOpen(false);
          submitEmail();
        }}
        isOpen={confirmEmailModalOpen}
      />
    </>
  );
};

export default FormEmail;
