import axios from 'axios';
import { Config } from 'config/env';
import {UserToken, UserSession, HttpRequestHandler} from '@rmstransactions/components';

HttpRequestHandler.setCommonApiKeyHeader()

const {
  apiHost,
  apiConfirmationPath,
  apiTransactionPath,
  apiiCalPath,
  apiViewReceiptPath,
  apiViewReceiptiCalPath,
  apiSupportConfirmationPath,
  apiSupportiCalPath,
} = Config;

let request = {
  url: '',
  config: {},
  clear: function () {
    this.url = '';
    this.config = {};
  },
};
export const getConfirmationDetails = async (uuid: string) => {
  request.clear();
  if (UserSession.isLoginUser()) {
    request.url = apiHost + apiConfirmationPath;
    request.config = {
      withCredentials: true,
      headers: { 'x-token': UserToken.getValue() },
    };
  } else {
    if (UserToken.getValue()) {
      request.url = apiHost + apiViewReceiptPath;
      request.config = {
        withCredentials: true,
        headers: { 'x-token': UserToken.getValue() },
      };
    } else {
      request.url = apiHost + apiSupportConfirmationPath + '/' + uuid;
      request.config = {
        withCredentials: false,
      };
    }
  }
  return await axios.get(request.url, request.config);
};

export const getConfirmationDetailsMock = async (transactionId: string) => {
  const url = apiHost + apiTransactionPath + transactionId;
  return await axios.get(url);
};

export const getiCal = async (uuid: string) => {
  request.clear();
  if (UserSession.isLoginUser()) {
    request.url = apiHost + apiiCalPath;
    request.config = {
      withCredentials: true,
      responseType: 'blob',
      headers: { 'x-token': UserToken.getValue() },
    };
  } else {
    if (UserToken.getValue()) {
      request.url = apiHost + apiViewReceiptiCalPath;
      request.config = {
        withCredentials: true,
        responseType: 'blob',
        headers: { 'x-token': UserToken.getValue() },
      };
    } else {
      request.url = apiHost + apiSupportiCalPath + uuid;
      request.config = {
        withCredentials: false,
        responseType: 'blob',
      };
    }
  }
  return await axios.get(request.url, request.config);
};

export function base64toBlob(data: string) {
  const bytes = atob(data);
  let length = bytes.length;
  let out = new Uint8Array(length);
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  return new Blob([out], { type: 'application/pdf' });
}
