import React, { useContext } from 'react';
import { AppState, store } from 'providers/globalState';
import { MoreInfoModal } from '@snsw-gel/react';
import CanIChangeTheTerms from './CanIChangeTheTerms';
import { ModalWrapper } from '../EligibilityFlags/styled';
import { helpers } from '@rmstransactions/components';

const RenewalTermToolTip = () => {
  const { globalState, dispatch } = useContext(store) as {
    globalState: AppState;
    dispatch: any;
  };
  const { reviewDetailsInput, validateData, usageType, selectedTerm } =
    globalState;

  const isVehicleLightWeight =
    parseInt(reviewDetailsInput?.registration?.registeredVehicle?.gvm) <= 4500;

  let registrationScheme = '';

  if (
    helpers.isNotEmptyValue(reviewDetailsInput?.registration?.registrationID)
  ) {
    registrationScheme = 'Full';
  } else if (
    helpers.isNotEmptyObject(
      reviewDetailsInput?.registration?.registeredVehicle
    ) &&
    helpers.isNotEmptyValue(
      reviewDetailsInput?.registration?.registeredVehicle?.vehicleID
    )
  ) {
    registrationScheme = 'Conditional';
  }
  return (
    <ModalWrapper data-testid='renewal-term-tool-tip'>
      <MoreInfoModal
        helpText={
          <CanIChangeTheTerms
            validateData={validateData?.renewRegistrationValidation}
            reviewDetailsInput={reviewDetailsInput}
            registrationScheme={registrationScheme}
            usageType={usageType}
            selectedTerm={selectedTerm}
            isVehicleLightWeight={isVehicleLightWeight}
          />
        }
        title='Can I change the renewal term?'
        questionIconFlag
      />
    </ModalWrapper>
  );
};

export default RenewalTermToolTip;
